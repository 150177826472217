import React, { useEffect } from "react";
import { navigate } from "gatsby";

import SEO from "../components/atoms/seo";
import Layout from "../components/organisms/layout";
import Heading from "../components/molecules/heading";

import content from "../data/pages/404/content.json";

const NotFoundPage = () => {
  useEffect(() => {
    navigate("/lp/product-page/arthromax/");
  }, []);

  return (
    <Layout hideSecureForm>
      <SEO title={content.seo.title} />
      <Heading
        title={content.heading.title}
        subTitle={content.heading.subTitle}
        weight="light"
      />
    </Layout>
  );
};

export default NotFoundPage;
